body {
    overflow-x: hidden;
}

.ttr-width-100-pc {
    width: 100%;
}

.ttr-height-100-pc {
    height: 100%;
}

.ttr-height-50-pc {
    height: 50%;
}

.ttr-rounded-15px {
    border-radius: 15px;
}

.ttr-font-size-150pc {
    font-size: 1.5em;
}

.ttr-width-50px {
    width: 50px;
}
.ttr-width-150px {
    width: 150px;
}

.left-0 {
    left: 0px;
}

.ttr-top-75px {
    top: 75px;
}

.ttr-top-40px {
    top: 40px;
}

.ttr-z-index-1 {
    z-index: 1;
}

.app-card {
    font-family: sans-serif;
    text-align: center;
    align-self: center;
  }
  
  header {
    margin-bottom: 30px;
  }
  
  .col-md-4 {
    margin-bottom: 30px;
  }
  
  .card {
    padding: 30px;
    width: max-content;
    height: auto;
    border: solid 1px rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
  .card:hover {
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.08);
  }
  .card-title {
    margin-top: 20px;
  }
  .card-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
  .card-text .fa {
    font-size: 26px;
  }
  .avatar {
    width: 140px;
    margin: auto;
  }
  .avatar img {
    border: solid 6px transparent;
    border-radius: 50%;
  }
  .card:hover .avatar img {
    border-color: rgb(99, 204, 131);
  }
  
  .btn {
    border-radius: 25px;
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1040px;
    }
  }
  
  